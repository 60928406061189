

















































































import cloneDeep from 'lodash/cloneDeep'

import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState, mapActions } from 'vuex'
import { STORE_KEY, store } from './_store'

import TitleBar from '@/components/Elements/TitleBar.vue'

@Component({
  computed: {
    ...mapState(STORE_KEY, [
      'loading', 'loaded', 'fatalError',

      'filter',

      'documents', 'lastMeta',
    ]),
  },
  methods: {
    ...mapActions(STORE_KEY, [
      'exportData',
    ]),
  },
  components: {
    TitleBar,
  },
})
export default class Nomenclature extends Vue {

  get titleStack() {
    return [
      'Товарная номенклатура',
    ]
  }

  public getQueryParam(field: string) {
    return this.$route.query ? cloneDeep(this.$route.query[field]) : null
  }

  // ---------------------------------------------------------------------------

  get defaultSort() {
    return [this.$route.query.sortField || 'name', this.$route.query.sortDirection || 'asc']
  }

  public onSort(field: string, direction: string) {
    this.$router.replace({
      query: {
        ...this.$route.query, sortField: field, sortDirection: direction,
      },
    })
  }

  public onSearch(filter: string, value: any) {
    this.$router.replace({
      query: {
        ...this.$route.query, [filter]: value,
      },
    })
  }

  public onPageChange(page: string) {
    this.$router.replace({
      query: { ...this.$route.query, page },
    })
  }

  public tableClick(row: any) {
    this.$router.push({ name: 'nomenclatures-edit', params: { id: row.id } })
  }

  // ---------------------------------------------------------------------------

  @Watch('$route.query')
  public handleParamsChange() {
    this.$store.dispatch(`${STORE_KEY}/loadData`, this.$route.query)
  }

  public created() {
    // @ts-ignore
    if (!(STORE_KEY in this.$store._modules.root._children)) {
      this.$store.registerModule(STORE_KEY, store)
    }
  }

  public mounted() {
    this.handleParamsChange()
  }

  public destroyed() {
    this.$store.unregisterModule(STORE_KEY)
  }

}
